require("./bootstrap");
import intlTelInput from "intl-tel-input";

var EncantosFrontFunctionality = (function () {
    const initProductSlider = () => {
        /* $(".slider-main").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: ".slider-nav",
        });

        $(".slider-nav").slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: ".slider-main",
            dots: false,
            centerMode: true,
            focusOnSelect: true,
            vertical: true,
        }); */

        var $thumbnails = $(".slider-nav");
        var $mainImage = $("#main-product-image");

        $thumbnails.on("click", "img", function () {
            var $this = $(this);
            var mainImageSrc = $this.data("main-image");
            $mainImage.css("opacity", 0); // Establecer opacidad a 0 para el efecto de desvanecimiento
            setTimeout(function () {
                $mainImage.attr("src", mainImageSrc).css("opacity", 1); // Cambiar la imagen y restablecer la opacidad
            }, 300); // Establecer un retraso para la transición
        });

        $thumbnails.slick({
            slidesToShow: 4, // Número de thumbnails a mostrar en escritorio
            slidesToScroll: 1, // Número de thumbnails a desplazar
            dots: false,
            centerMode: true,
            focusOnSelect: true,
            vertical: true,
            responsive: [
                {
                    breakpoint: 768, // Cambio en la vista móvil
                    settings: {
                        slidesToShow: 3, // Número de thumbnails a mostrar en vista móvil
                        slidesToScroll: 3, // Número de thumbnails a desplazar en vista móvil
                        vertical: false, // Mostrar los thumbnails en fila
                    },
                },
            ],
        });
    };

    const initSponsorsCarousel = () => {
        $(".sponsors-carousel").slick({
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 5000,
        });
    };

    const initCategorySliders = () => {
        $(".category-slide").slick({
            arrows: false,
            autoplay: true,
            autoplaySpeed: 4000,
            infinite: true,
        });
    };

    const validateFiltersInProducts = () => {
        // Validate min price and max price
    };

    const getProductVariation = () => {
        $("#productDetails input").on("change", function () {
            if ($("#productDetails input[name=quantity]").val() > 0) {
                $.ajax({
                    type: "POST",
                    url: getVariantPriceRoute,
                    data: $("#productDetails").serializeArray(),
                    success: function (data) {
                        // check stock of product
                        if (data.quantity < 1) {
                            $("#productDetails #addToCart").hide();
                            $("#productDetails #outOfStock").show();
                        } else {
                            $("#productDetails #addToCart").show();
                            $("#productDetails #outOfStock").hide();

                            // with the max_limit change the max value of #quantity
                            $("#productDetails #quantity").attr({
                                max: Number(data.max_limit),
                            });
                        }

                        // Set the price
                        $("#productDetails #price").html(data.price);
                    },
                });
            }
        });
    };

    const handleMinusPlusButtonsInProduct = () => {
        $("#productDetails").on(
            "click",
            "button.plus, button.minus",
            function () {
                // Get current quantity values
                var qty = $(this).closest("#productDetails").find("#quantity");
                var val = parseFloat(qty.val());
                var max = parseFloat(qty.attr("max"));
                var min = parseFloat(qty.attr("min"));
                var step = 1;

                // Change the value if plus or minus
                if ($(this).is(".plus")) {
                    if (max && max <= val) {
                        qty.val(max);
                    } else {
                        qty.val(val + step);
                    }
                } else {
                    if (min && min >= val) {
                        qty.val(min);
                    } else if (val > 1) {
                        qty.val(val - step);
                    }
                }
            }
        );
    };

    const handleBillingAddressFieldsInCheckout = () => {
        $("input.billingAddress").on("change", function () {
            if ($(this).val() === "new") {
                $("#billingForm").removeClass("d-none");
            } else {
                $("#billingForm").addClass("d-none");
            }
        });
    };

    const initWishlistButton = () => {
        $(".likeProduct").on("click", function (e) {
            e.preventDefault();
            let button = $(this);
            let data = button.data();
            $.ajax({
                type: "POST",
                headers: {
                    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                        "content"
                    ),
                },
                url: wishlistStoreRoute,
                data: data,
                success: function (data) {
                    // Add or remove liked class to product
                    if (data.status === "success") {
                        button.toggleClass("liked");
                    }
                },
                error: function (error) {
                    console.error(error);
                },
            });
        });
    };

    const initPhoneInput = () => {
        const input = document.querySelector("#phone");
        intlTelInput(input, {
            utilsScript:
                "https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js",
            preferredCountries: ["mx", "us"],
        });
        /* intlTelInput(input, {
            // any initialisation options go here
            preferredCountries: ["mx", "us"],
        }); */
    };

    // Public methods
    return {
        init: function () {
            initProductSlider();
            initSponsorsCarousel();
            initCategorySliders();
            validateFiltersInProducts();
            getProductVariation();
            handleMinusPlusButtonsInProduct();
            handleBillingAddressFieldsInCheckout();
            initWishlistButton();
            initPhoneInput();
        },
    };
})();

document.addEventListener("DOMContentLoaded", function () {
    EncantosFrontFunctionality.init();
});
